import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import Helmet from "react-helmet"
import Navbar from "./navbar"
import { Container } from "@material-ui/core"
import { CssBaseline } from "@material-ui/core"

export default function Layout({ children }) {
  const { title } = useSiteMetadata()
  return (
    <>
      <CssBaseline />
      <Container maxWidth="xl">
        <Helmet title={title} defer={false} />
        <Navbar />
        {children}
      </Container>
    </>
  )
}
