import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"

const useStyles = makeStyles(theme => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    overflowX: "auto",
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}))

export default function Navbar() {
  const classes = useStyles()
  const navLinks = [
    { name: "Owners", url: "/owners" },
    { name: "Seasons", url: "/seasons" },
    { name: "Records", url: "/records" },
    { name: "Charter", url: "/charter" },
  ]

  return (
    <div>
      <Toolbar className={classes.toolbar}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          className={classes.toolbarTitle}
        >
          <Link color="inherit" component={GatsbyLink} to="/" underline="none">
            The CCFFL
          </Link>
        </Typography>
      </Toolbar>
      <Toolbar
        component="nav"
        variant="dense"
        className={classes.toolbarSecondary}
      >
        {navLinks.map(navLink => (
          <Link
            component={GatsbyLink}
            to={navLink.url}
            color="inherit"
            noWrap
            key={navLink.name}
            variant="body2"
            className={classes.toolbarLink}
          >
            {navLink.name}
          </Link>
        ))}
      </Toolbar>
    </div>
  )
}
